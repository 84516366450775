import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'
import Config from '@/config/config'

Vue.use(Vuex)
// 之後要大整修，因為全部使用directly control state attrs，高風險崩潰
export default new Vuex.Store({
  state: {
    testState: ''
  },
  // get
  getters: {
    getData: state => {
      return state.testState
    }
  },
  // for sync func用途
  mutations: {
    setData: (state, res) => {
      console.log('Set data mutation')
      state.testState = res
      // checkpoint
      console.log(state.testState)
    }
  },
  // for async func用途
  actions: {
    getData: async context => {
      // 承接json header設定
      // const userRequest = Axios.create({
      //   baseURL: 'https://www.for-paper-proxy.binyi.fun/',
      //   headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
      // })
      const userRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        headers: { Accept: 'html/text' }
      })
      console.log('get data action')
      await userRequest.get('/hello2').then(res => {
        console.log('this is in the promise')
        context.commit('setData', res)
      }).catch(err => {
        console.log(err)
      })
    }
  },
  modules: {}
})
