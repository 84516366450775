<template xmlns:wire="http://www.w3.org/1999/xhtml">
  <div class="px-2">
    <div class="sticky top-0 z-50 bg-gray-50 border-b-4 pb-1.5">
      <div class="flex pt-2">
        <div class="flex flex-row items-center ml-4">
          <label id="listbox-label1" class="fixed z-10 block relative text-3xl text-o_purple-100 font-semibold mr-2 z-20">
            產品
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="isActive1 = !isActive1" type="button"
                      class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
                {{ select_state1 }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="isActive1" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a @click.prevent="selectProductEvent($event)"
                   v-for="product in productList" :key="product"
                   href="#" class="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ product }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <button @click="refreshRecordAndNodeInfoEvent()"
                v-if="refreshActive"
                type="button"
                class=" ml-3 font-semibold
              inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
          重新整理
        </button>
      </div>
    </div>
    <div v-show="isLoading" wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-80 flex flex-col items-center justify-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <h2 class="text-center text-white text-xl font-semibold">處理中...</h2>
      <p class="w-1/3 text-center text-white">這需要一些時間, 請不要關閉此頁面或做其他操作</p>
    </div>
    <div class="grid grid-cols-8 grid-rows-7 grid-flow-col gap-1.5 mt-5 custom-h-inside">
      <!-- 下面內容為日曆     -->
      <!-- 下面內容為詞彙清單     -->
      <div class="rounded-2xl bg-white shadow-2xl mx-2 my-2 col-span-3 row-span-7 overflow-auto p-6">
        <h1 class="text-o_purple-200 text-3xl font-semibold">節點詞彙</h1>
        <!-- 下面內容為詞彙清單     -->
<!--        <ul role="list" class="divide-y divide-gray-200 m-5 my-3">-->
<!--          <li v-for="term in terms" :key="term.id" class="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset">-->
<!--            <div class="flex justify-between space-x-3">-->
<!--              <div class="min-w-0 flex-1">-->
<!--                <button href="#" class="block focus:outline-none">-->
<!--                  <p class="text-sm font-medium text-gray-900 truncate">{{ term.sender }}</p>-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="mt-1">-->
<!--              <P>各國(選擇性)</P>-->
<!--              <p>標籤類型: ...</p>-->
<!--              <p class="line-clamp-2 text-sm text-gray-600">-->
<!--                {{ term.content }}-->
<!--              </p>-->
<!--            </div>-->
<!--          </li>-->
<!--        </ul>-->
        <ul v-show="true" class="text-o_purple-100 text-3xl my-3">
          <li v-for="(nodeInfo, i) in nodeInfoList" :key="i" class="text-o_purple-100 text-3xl mb-4  mt-1 pt-4 border-b-4 border-t_dark_brown">
            <button @click="GetRecordContentWithoutAuthByNodeTag(nodeInfo.name, nodeInfo.type)"
                    v-if="true"
                    type="button"
                    class=" font-semibold mb-4
              inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
              {{nodeInfo.name}}
            </button><br v-show="nodeInfo.is_term">
            <span v-show="nodeInfo.is_term" class="font-semibold">各國翻譯(中/英/日):</span><br>
            <div class="pb-3.5" v-show="nodeInfo.is_term">{{nodeInfo.chinese}}/{{nodeInfo.english}}/{{nodeInfo.japanese}} </div>
            <span class="font-semibold">內容:</span><br>
            <div class="pb-3.5">{{ nodeInfo.content }}</div>
          </li>
        </ul>
      </div>
      <div class="rounded-2xl bg-white shadow-2xl mx-2 my-2 col-span-5 row-span-full overflow-auto p-6" ref="canvas">
        <h1 class="text-o_purple-200 text-3xl font-semibold">區塊鏈紀錄</h1>
        <!--  下面這是區塊鏈紀錄表單   -->
        <div class="flex flex-col m-3 my-6">
          <div class="-my-2 sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div v-show="tableActive" class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mb-5">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Id
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      時間
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      地點
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      操作
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      細節
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      產品
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      產品批號
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      驗證
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      註銷
                    </th>
                    <th scope="col" class="relative px-4 py-3">
                      <span class="sr-only">註銷</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="content in contents" :key="content.record_id">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {{ content.record_id }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {{ content.time }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {{ content.place }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {{ content.operation }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {{ content.detail }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {{ content.product }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {{ content.product_code }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span v-if="!content.verified" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    未驗證
                  </span>
                      <span v-else class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    已驗證
                  </span>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span v-if="!content.error" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    未註銷
                  </span>
                      <span v-else class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    已註銷
                  </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import RecordService from '../services/record.service'
import Axios from 'axios'
import Config from '@/config/config'
export default {
  name: 'TermAndBlockchain',
  methods: {
    // 20220214 詞彙與區塊鏈頁面-初始取得產品清單
    // 獲得productList: 初始自動取得產品清單，為GET，到頁面會自動執行獲得產品清單，下拉選單用途(需要非同步處理/不用鎖畫面)
    // GET https://www.ricehouse-backend.ntu.asia/graph/product-list
    async GetProductList () {
      const productListRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await productListRequest.get('/graph/product-list').then(res => {
        const resTemp = res.data
        // console.log(resTemp.productList)
        // 這類型的async task最好不要採用回傳賦值得作法，很容易導致整格程序卡住
        this.productList = resTemp.result
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          return false
        }
      })
    },
    // 20220214 詞彙與區塊鏈頁面-產品選擇-依據產品取得所有產品節點細節
    // 依據產品選項去取得相關的節點訊息(詞彙內容)，會鎖畫面，因為數量多操作單純，加上會同時取得該產品的所有操作紀錄 (需要非同步處理/要鎖畫面)
    // POST https://www.ricehouse-backend.ntu.asia/graph/node-info
    async GetNodeInfo (product) {
      this.isLoading = true
      const dataForm = new FormData()
      this.termInfoList = {}
      this.nodeInfoList = []
      dataForm.append('product', product)
      const nodeInfoRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await nodeInfoRequest.post('/graph/node-info', dataForm).then(res => {
        const resTemp = res.data
        // 下面這條很特殊，若是我們沒有取得response等於他會變成空的，所以也會觸發，empty屬於false在js中，因此沒東西或者is_err=false都可以觸發
        // 而上面這巧合，也順帶因為進去result為空的因此幫我們清空，這是比較奇妙的處理方式
        if (!resTemp.is_err) {
          this.nodeInfoList = resTemp.result
        }
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      this.isLoading = false
    },
    // 20220214 詞彙與區塊鏈頁面-產品選擇-依據產品取得所有相關操作紀錄 and 詞彙與區塊鏈頁面-詞彙獲取操作紀錄
    // 20220214 詞彙與區塊鏈頁面-詞彙獲取操作紀錄 and 詞彙與區塊鏈頁面-產品選擇-依據產品取得所有相關操作紀錄
    // 會依據產品來獲取所有的操作紀錄，會鎖住螢幕，操作簡單加上交互內容頻率低，同時會取得許多的節點資訊，這樣條件下適合採用鎖住螢幕設置 (需要非同步處理/要鎖畫面)
    // POST https://www.ricehouse-backend.ntu.asia/service/node-records
    async GetRecordContentWithoutAuthByNodeTag (node, tag) {
      this.isLoading = true
      this.tableActive = false
      // console.log('Getting record content ...')
      const dataForm = new FormData()
      dataForm.append('node', node)
      dataForm.append('tag', tag)
      const nodeInfoRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await nodeInfoRequest.post('/service/node-records', dataForm).then(res => {
        const resTemp = res.data
        // 這個API回傳僅有一個result array因此錯誤為null反之則有內容，有內容就執行
        if (resTemp) {
          this.contents = resTemp.result
          this.tableActive = true
        }
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      this.isLoading = false
    },
    ShowNodeInfoAndRecordWithProduct (product) {
      this.isLoading = true
      // console.log(event.target.innerText)
      this.GetNodeInfo(product)
      this.isLoading = false
    },
    // 下面這個是重新整理按下去event，會以當前的選取的產品this.select_state1去獲取records、node-infos
    // 用途是是選了node過濾後可以返回到原本product下所有records，另一種是可能當前records、node-info後台資料庫內容有更新可以refresh
    refreshRecordAndNodeInfoEvent () {
      this.ShowNodeInfoAndRecordWithProduct(this.select_state1)
      this.GetRecordContentWithoutAuthByNodeTag(this.select_state1, 'product')
    },
    async selectProductEventByURL (product) {
      // 下面這個是點擊縮、放選單
      // this.isActive1 = !this.isActive1
      // 重新整理按鈕致能
      this.refreshActive = true
      // 選後把把選擇狀態顯示出來
      this.select_state1 = product
      await this.ShowNodeInfoAndRecordWithProduct(this.select_state1)
      await this.GetRecordContentWithoutAuthByNodeTag(this.select_state1, 'product')
      // ----------------------------下面為將選取的內容，同步到URL上，方便使用者複製建立QR code提供商品連結到該產品的選項
      const url = new URL(window.location)
      url.searchParams.set('product', this.select_state1)
      // console.log(this.$route.name)
      // console.log(url.href)
      // url.href = url.href + this.$route.name.toLowerCase()
      // console.log(url)
      window.history.pushState({}, '', url)
      // 下面這個是點擊縮、放選單
      // this.isActive1 = !this.isActive1
    },
    async selectProductEvent (event) {
      // 下面這個是點擊縮、放選單
      this.isActive1 = !this.isActive1
      // 重新整理按鈕致能
      this.refreshActive = true
      // 選後把把選擇狀態顯示出來
      this.select_state1 = event.target.innerText
      await this.ShowNodeInfoAndRecordWithProduct(this.select_state1)
      await this.GetRecordContentWithoutAuthByNodeTag(this.select_state1, 'product')
      // ----------------------------下面為將選取的內容，同步到URL上，方便使用者複製建立QR code提供商品連結到該產品的選項
      const url = new URL(window.location)
      url.searchParams.set('product', this.select_state1)
      // console.log(this.$route.name)
      // console.log(url.href)
      // url.href = url.href + this.$route.name.toLowerCase()
      url.href = url.href + 'term-blockchain'
      window.history.pushState({}, '', url)
    }
  },
  data () {
    return {
      isActive1: false,
      refreshActive: false,
      tableActive: false,
      productList: [],
      productCodeList: [],
      termInfoList: {},
      termFormattingList: {},
      nodeInfoList: [],
      select_state1: '請選擇',
      isLoading: false,
      editForm: false,
      contents: {},
      people: [
        { id: '1', code: '2020-C1_鹿鳴米_張天賞', operation: '插秧', detail: '插秧機', time: '2020-01-21 19:45', place: '張天賞有機田', state: true },
        { id: '2', code: '2020-C1_鹿鳴米_許正波', operation: '整地', detail: '拖曳機', time: '2020-03-21 14:45', place: '許正波有機田', state: false },
        { id: '3', code: '2020-C1_鹿鳴米_張天賞', operation: '稻米碾製與儲存', detail: '低溫倉儲', time: '2020-05-11 16:45', place: '張天賞有機田', state: false },
        { id: '4', code: '2020-C1_鹿鳴米_張天賞', operation: '肥培管理', detail: '穢肥', time: '2020-06-11 19:45', place: '張天賞有機田', state: true },
        { id: '3', code: '2020-C1_鹿鳴米_張天賞', operation: '稻米碾製與儲存', detail: '低溫倉儲', time: '2020-05-11 16:45', place: '張天賞有機田', state: false },
        { id: '3', code: '2020-C1_鹿鳴米_張天賞', operation: '稻米碾製與儲存', detail: '低溫倉儲', time: '2020-05-11 16:45', place: '張天賞有機田', state: false },
        { id: '3', code: '2020-C1_鹿鳴米_張天賞', operation: '稻米碾製與儲存', detail: '低溫倉儲', time: '2020-05-11 16:45', place: '張天賞有機田', state: false }
      ],
      nameTemp: '',
      emailTemp: '',
      confirmTemp: false,
      terms: [
        { sender: '注水', content: '水資源管理: 將水注入土地中。' },
        { sender: '收穫及碾製', content: ' 收割與收割後: 收割時候會有以下操作針對作物，包含收割脫粒、堆放、搬運與清潔， 收割後有下列步驟來進行處理，包含乾燥、儲存、碾製與最終加工。' },
        { sender: '農藥快篩', content: ' 收割後: 農藥殘留測試。' },
        { sender: '運送', content: '收割後: 透過交通工具將稻米運送到目的地，而該交通工具並須經過清潔、消毒與能正常運作而該交通工具並須經過清潔、消毒與能正常運作而該交通工具並須經過清潔、消毒與能正常運作而該交通工具並須經過清潔、消毒與能正常運作。' }
      ]
    }
  },
  mounted () {
    this.GetProductList()
    // 初始化應該是啥都不顯示，這下面是測試用的
    // RecordService.getRecordContentWithoutAuth().then(
    //   res => {
    //     this.contents = res.data.result
    //     console.log(this.contents)
    //     // 之後要改成取得資料就關閉，現在是測試
    //     // setTimeout(() => { this.isLoading = false }, 1200)
    //     this.isLoading = false
    //   }
    // ).catch(() => {
    //   console.log('Got records err')
    //   this.isLoading = false
    // })
    // ------------會偵測url是否有product參數，若有自動選取，反之不做事
    const url = new URL(window.location)
    if (url.searchParams.get('product')) {
      this.selectProductEventByURL(url.searchParams.get('product'))
    }
  }
}
</script>

<style scoped>
.custom-h-inside {
  height: 1000px;
}
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
