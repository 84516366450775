<template>
  <footer class="flex flex-col justify-center bg-o_purple-100 text-t_white text-lg py-7 pb-9 rounded-2xl">
      <p class="mt-2 text-center" v-for="info in footers" :key="info">{{ info }}</p>
      <a target="_blank" class="mt-2 text-center hover:underline transition:ease-out duration-300" :href="traceabilityMobilePageURL">手機版簡易頁面</a>
<!--      <a target="_blank" class="mt-2 text-center hover:underline transition:ease-out duration-300" href="https://www.ricehouse-traceability-en.ntu.asia/">English Version-Desktop Page</a>-->
  </footer>
</template>

<script>
import Config from '@/config/config'

export default {
  name: 'Footer',
  data: function () {
    return {
      traceabilityMobilePageURL: Config.traceabilityMobilePageURL,
      footers: [
        '行政院農委會 計畫補助',
        '國立台灣大學生物環境系統工程學系 維運',
        '106 台北市大安區羅斯福路四段1號',
        // '服務電話：+886-2-33663468',
        '版本: 1.0.1 更新於 2022/12/14 11:06'
      ]
    }
  }
}
</script>

<style scoped>

</style>
